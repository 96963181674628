import * as React from 'react';
import {useMoralis, useNFTBalances} from 'react-moralis';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {useCansUnclaimed} from '../hooks/useCansUnclaimed';
import {useDebounce} from '../hooks/useDebounce';
import {NftResult} from '../hooks/useVerifyMetadata';
import {Cans, NFTCard} from '.';

function NFTBalance() {
  const sodaContract = '0xb184b9414e7d7c436b7097ed2c774bb56fae392f'; // Mainnet
  //   const sodaContract = '0xf18f53605e43846f80ab1e4af16e5dcf01a35da3'; // Testnet
  const {data: nftBalances, getNFTBalances} = useNFTBalances(undefined, {
    autoFetch: false,
  });
  const {account} = useMoralis();
  const {unclaimedBalances, fetchAllBalances} = useCansUnclaimed();
  const {debounce} = useDebounce();

  const [selectedIndices, setSelectedIndices] = React.useState<number[]>([]);
  const [currentScroll, setCurrentScroll] = React.useState<number | null>(null);

  const [width, setWidth] = React.useState(window.innerWidth);
  const debouncedWidth = debounce<number>(width);

  const canScroll = (element: HTMLDivElement) => {
    let result = !!element.scrollLeft;
    if (!result) {
      element.scrollLeft = 1;
      result = !!element.scrollLeft;
      element.scrollLeft = 0;
    }
    return result;
  };

  React.useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
      const sodasGrid = document.getElementById('sodas-grid') as HTMLDivElement;
      setCurrentScroll(canScroll(sodasGrid!) ? currentScroll : null);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [debouncedWidth]);

  const sodas: NftResult[] = (
    !nftBalances?.result ? Array.from(new Array(6)) : nftBalances?.result
  )
    .filter(nft => !nft || nft?.token_address === sodaContract)
    .sort((a, b) => a.token_id - b.token_id);

  const firstUpdate = React.useRef(true);

  React.useEffect(() => {
    getNFTBalances();
  }, [account]);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const ids = sodas.map(soda => soda.token_id);
    fetchAllBalances(ids);
  }, [nftBalances]);

  const totalUnclaimed = React.useMemo(
    () =>
      unclaimedBalances
        .filter((_, index) => selectedIndices.includes(index))
        .reduce((total, can) => total + can.balance, 0),
    [selectedIndices]
  );

  const onCardSelected = (index: number) => {
    const newSelectedIndices = [...selectedIndices];
    const selectedIndex = newSelectedIndices.indexOf(index);
    if (selectedIndex > -1) {
      newSelectedIndices.splice(selectedIndex, 1);
    } else {
      newSelectedIndices.push(index);
    }
    setSelectedIndices(newSelectedIndices);
  };

  const gridWrapper = document.getElementById('grid-wrapper') as HTMLDivElement;

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const scrollbarWidth = target.scrollWidth - gridWrapper.offsetWidth;
    const hasScrollbar = target.scrollWidth > target.clientWidth;
    setCurrentScroll(hasScrollbar ? target.scrollLeft / scrollbarWidth : null);
  };

  const shadow = {
    position: 'absolute',
    top: 0,
    zIndex: 100,
    height: '100%',
    width: '10px',
  };

  const leftShadow = {
    ...shadow,
    left: 0,
    background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.35), transparent)',
    opacity: currentScroll !== null ? currentScroll : 0,
  };

  const rightShadow = {
    ...shadow,
    right: 0,
    background: 'linear-gradient(270deg, rgba(0, 0, 0, 0.35), transparent)',
    opacity: currentScroll !== null ? 1 - currentScroll : 0,
  };

  return (
    <React.Fragment>
      <Box sx={{flexGrow: 1}}>
        <Typography
          gutterBottom
          variant="h3"
          sx={{
            textShadow: '-2px 2px 4px black',
            color: 'white',
            textTransform: 'uppercase',
          }}
        >
          Select Your SODAs
        </Typography>
        <Box
          id="grid-wrapper"
          sx={{position: 'relative', margin: '0 auto', overflow: 'hidden'}}
        >
          <Box sx={{...leftShadow}} />
          <Box sx={{...rightShadow}} />
          <Grid
            id="sodas-grid"
            container
            spacing={{xs: 2, md: 3}}
            flexWrap="nowrap"
            mt={3}
            onScroll={handleScroll}
            sx={{overflowX: 'scroll'}}
          >
            {sodas.map((nft, index) => (
              <Grid item key={index}>
                <NFTCard
                  nft={nft}
                  balance={unclaimedBalances[index]?.balance}
                  selected={selectedIndices.includes(index)}
                  onClick={() => onCardSelected(index)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box my={2}>
          <Button
            variant="contained"
            disabled={
              selectedIndices.length === sodas.length || !nftBalances?.result
            }
            onClick={() =>
              setSelectedIndices(Array.from(Array(sodas.length).keys()))
            }
            sx={{borderRadius: 99, mx: 2}}
          >
            Select All
          </Button>
          <Button
            variant="contained"
            disabled={selectedIndices.length < 1}
            onClick={() => setSelectedIndices([])}
            sx={{borderRadius: 99, mx: 2}}
          >
            Clear All
          </Button>
        </Box>
      </Box>
      <Cans
        totalUnclaimed={totalUnclaimed}
        claimIds={unclaimedBalances
          .filter((_, index) => selectedIndices.includes(index))
          .map(can => parseInt(can.tokenId))}
      />
    </React.Fragment>
  );
}

export default NFTBalance;
