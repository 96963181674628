import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Welcome = () => {
  return (
    <Box sx={{mt: 5}}>
      <Typography
        gutterBottom
        variant="h1"
        sx={{
          textShadow: '-2px 2px 4px black',
          color: 'white',
          fontWeight: 500,
        }}
      >
        SODA CAN CLAIMING
      </Typography>
    </Box>
  );
};

export default Welcome;
