import Moralis from 'moralis';
import MetaMask from './WalletIcons/metamaskWallet.png';
import WalletConnect from './WalletIcons/wallet-connect.svg';
import TrustWallet from './WalletIcons/TrustWallet.png';
import CoinbaseWallet from './WalletIcons/CoinbaseWallet.png';

interface Connector {
  title: string;
  icon: string;
  connectorId?: Moralis.Web3ProviderType | 'coinbasewallet';
  priority: number;
}

export const connectors: Connector[] = [
  {
    title: 'MetaMask',
    icon: MetaMask,
    priority: 1,
  },
  {
    title: 'WalletConnect',
    icon: WalletConnect,
    connectorId: 'walletconnect',
    priority: 2,
  },
  {
    title: 'Coinbase Wallet',
    icon: CoinbaseWallet,
    connectorId: 'coinbasewallet',
    priority: 3,
  },
  {
    title: 'Trust Wallet',
    icon: TrustWallet,
    priority: 4,
  },
];
