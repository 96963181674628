import {useState} from 'react';
import {useIPFS} from './useIPFS';

export interface NftResult {
  image?: string | null;
  token_address: string;
  token_id: string;
  contract_type: string;
  owner_of: string;
  block_number: string;
  block_number_minted: string;
  token_uri?: string;
  metadata?: unknown;
  synced_at?: string;
  amount?: string;
  name: string;
  symbol: string;
}

export const useVerifyMetadata = () => {
  const {resolveLink} = useIPFS();
  const [results, setResults] = useState<Record<string, NftResult>>({});

  function verifyMetadata(nft: NftResult): NftResult {
    if (nft.metadata) return nft;
    getMetadata(nft);
    if (nft.token_uri) {
      return results?.[nft.token_uri] ? results?.[nft.token_uri] : nft;
    }
    return nft;
  }

  async function getMetadata(nft: NftResult) {
    if (!nft.token_uri || !nft.token_uri.includes('://')) {
      console.log('getMetadata invalid URI', {uri: nft.token_uri, nft});
      return;
    }
    try {
      const response = await fetch(nft.token_uri);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const metadata = await response.json();
      console.log(`Metadata: ${metadata}`);
      if (!metadata) {
        console.error(
          'useVerifyMetadata.getMetadata no metadata found on URI:',
          {uri: nft.token_uri, nft}
        );
      } else if (
        metadata?.detail &&
        metadata.detail.includes('Request was throttled')
      ) {
        console.warn(
          `useVerifyMetadata.getMetadata bad result for ${nft.token_uri} will retry later`,
          {results, metadata}
        );
        setTimeout(() => getMetadata(nft), 1000);
      } else {
        setMetadata(nft, metadata);
        console.log(`getMetadata late load for NFT metadata ${nft.token_uri}`, {
          metadata,
        });
      }
    } catch (e) {
      console.error('useVerifyMetadata.getMetadata error caught:', {
        e,
        nft,
        uri: nft.token_uri,
      });
    }
  }

  function setMetadata(nft: NftResult, metadata: any) {
    nft.metadata = metadata;

    if (metadata?.image) nft.image = resolveLink(metadata.image);

    if (metadata && nft.token_uri && !results[nft.token_uri]) {
      setResults({...results, [nft.token_uri]: nft});
    }
  }

  return {verifyMetadata};
};
