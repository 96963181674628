import {useState} from 'react';
import {useMoralis} from 'react-moralis';
import {useSnackbar} from 'notistack';
import cansAbi from '../contracts/Cans.json';

interface CansUnclaimed {
  tokenId: string;
  balance: number;
}

const cansContract = process.env.REACT_APP_CANS_CONTRACT;

export const useCansUnclaimed = () => {
  //const sodaContract = '0xb184b9414e7d7c436b7097ed2c774bb56fae392f'; // Mainnet
  const {Moralis, chainId} = useMoralis();
  const [unclaimedBalances, setUnclaimedBalances] = useState<CansUnclaimed[]>(
    []
  );
  const [totalUnclaimed, setTotalUnclaimed] = useState(0);
  const {enqueueSnackbar} = useSnackbar();

  async function fetchUnclaimedBalance(id: string) {
    const options = {
      contractAddress: cansContract!,
      functionName: 'cansUnclaimed',
      abi: cansAbi,
      chainId: chainId,
      params: {tokenId: parseInt(id)},
    };
    const message = await Moralis.executeFunction(options);
    return message;
  }

  async function fetchAllBalances(ids: string[]) {
    const promises = ids.map(id => fetchUnclaimedBalance(id));

    try {
      const values = await Promise.all(promises);
      const mapped = values.map((v, idx) => ({
        tokenId: ids[idx],
        balance: parseInt(v.toString()),
      }));

      setUnclaimedBalances(mapped);
      setTotalUnclaimed(getTotal());
    } catch (error: any) {
      enqueueSnackbar('There was an error fetching the cans balances.', {
        variant: 'error',
        autoHideDuration: 12000,
        preventDuplicate: true,
      });
    }
  }

  function getTotal() {
    return unclaimedBalances.reduce(
      (total, unclaimed) => total + unclaimed.balance,
      0
    );
  }

  return {
    totalUnclaimed,
    setTotalUnclaimed,
    unclaimedBalances,
    fetchAllBalances,
    getTotal,
  };
};
