import {useMoralis, useWeb3ExecuteFunction} from 'react-moralis';
import {useSnackbar} from 'notistack';

import contractAbi from '../contracts/Cans.json';

const CANS_ADDRESS = process.env.REACT_APP_CANS_CONTRACT;

export const useClaim = () => {
  const {chainId} = useMoralis();
  const contractProcessor = useWeb3ExecuteFunction();
  const {enqueueSnackbar} = useSnackbar();

  async function claimCans(sodas: number[], quantities: number[]) {
    if (!CANS_ADDRESS) {
      console.error('ERROR: Missing REACT_APP_CANS_CONTRACT env variable');
      return;
    }

    const options = {
      contractAddress: CANS_ADDRESS,
      abi: contractAbi,
      functionName: 'claim',
      params: {
        tokenIds: sodas,
        amounts: quantities,
      },
      chainId: chainId,
    };

    await contractProcessor.fetch({
      params: options,
      onError: (error: any) => {
        enqueueSnackbar(
          error.reason || error.message || JSON.stringify(error),
          {
            variant: 'error',
            autoHideDuration: 10000,
          }
        );
      },
    });
  }

  return {contractProcessor, claimCans};
};
