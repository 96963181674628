import * as React from 'react';
import {keyframes} from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import {useIPFS} from '../hooks/useIPFS';
import {NftResult, useVerifyMetadata} from '../hooks/useVerifyMetadata';

interface NFTCardProps {
  nft?: NftResult;
  balance: number;
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const SODA_BASE_URI = 'ipfs://QmTzPNGNJbHNApuw1SVVkbimHD72p36YKYcSjNitZZSE7x/';

const BORDER_COLORS = [
  '#ffa3a3',
  '#ffd3a3',
  '#fff99c',
  '#a3ffac',
  '#a3e8ff',
  '#a7a3ff',
  '#ffa3a3',
];

const rainbow = keyframes`
  from {background-position: -20vh 0}
  to {background-position: 20vh 0}
`;

const rainbowStyles = {
  border: '5px solid transparent',
  borderRadius: '4px',
  height: 'calc(100% - 2px)',
  background: `linear-gradient(white, white), repeating-linear-gradient(to right, ${BORDER_COLORS.join(
    ', '
  )})`,
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box, border-box',
  animation: `${rainbow} 10s linear alternate infinite`,
};

function NFTCard({nft, balance, selected, onClick}: NFTCardProps) {
  const {verifyMetadata} = useVerifyMetadata();
  const {resolveLink} = useIPFS();

  if (nft) {
    nft = verifyMetadata(nft);
  }

  return (
    <Card
      variant="outlined"
      sx={{borderWidth: '5px', ...(selected && rainbowStyles)}}
    >
      <CardActionArea disabled={!nft} onClick={onClick}>
        <CardHeader
          title={nft ? 'SODA' : <Skeleton width="40%" sx={{mb: 2}} />}
          subheader={nft ? `#${nft.token_id}` : <Skeleton width="60%" />}
        />
        {nft ? (
          <CardMedia
            component="img"
            alt={nft.name}
            height={128}
            width={128}
            image={
              resolveLink(`${SODA_BASE_URI}${nft.token_id}.png`) || 'error'
            }
          />
        ) : (
          <Skeleton height={128} width={128} variant="rectangular" />
        )}
        <CardContent>
          <React.Fragment>
            <Typography variant="h6" component="span">
              {nft ? 'Balance:' : <Skeleton sx={{mb: 2}} />}
            </Typography>
            <Typography variant="body2" color="text.secondary" component="p">
              {nft ? balance : <Skeleton width="80%" />}
            </Typography>
          </React.Fragment>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default NFTCard;
