import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import {FaDiscord, FaTwitter, FaMediumM} from 'react-icons/fa';
import {ReactComponent as Opensea} from '../assets/opensea.svg';

function Footer() {
  return (
    <AppBar sx={{background: 'none', boxShadow: 0}} position="static">
      <Box px={{xs: 3, sm: 10}} py={{xs: 5, sm: 10}} color="white">
        <Container maxWidth="sm">
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item sx={{}}>
              <Box sx={{fontSize: '3rem'}}>
                <Link
                  href="https://twitter.com/DerivativeApes"
                  color="inherit"
                  target="_blank"
                  rel="noopener"
                >
                  <FaTwitter />
                </Link>
              </Box>
            </Grid>
            <Grid item sx={{}}>
              <Box sx={{fontSize: '3rem'}}>
                <Link
                  href="https://discord.gg/FJj2eQkFwP"
                  color="inherit"
                  target="_blank"
                  rel="noopener"
                >
                  <FaDiscord />
                </Link>
              </Box>
            </Grid>
            <Grid item sx={{}}>
              <Box sx={{fontSize: '3rem'}}>
                <Link
                  href="https://opensea.io/collection/sodativity-cans"
                  color="inherit"
                  target="_blank"
                  rel="noopener"
                >
                  <Opensea />
                </Link>
              </Box>
            </Grid>
            <Grid item sx={{}}>
              <Box sx={{fontSize: '3rem'}}>
                <Link
                  href="https://medium.com/p/7c5ba4091458/edit"
                  color="inherit"
                  target="_blank"
                  rel="noopener"
                >
                  <FaMediumM />
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </AppBar>
  );
}

export default Footer;
