import React, {useState} from 'react';
import Moralis from 'moralis';
import {useMoralis} from 'react-moralis';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';

import {getEllipsisTxt} from '../utils/formatters';
import {getExplorer} from '../utils/networks';
import Check from './Address/Check';

interface SuccessDialogProps {
  open: boolean;
  onClose: () => void;
  data: unknown;
}

export default function SuccessDialog(props: SuccessDialogProps) {
  const {chainId} = useMoralis();
  const {onClose, open, data} = props;
  const [clicked, setClicked] = useState(false);

  const hasHash = (
    data: unknown
  ): data is Moralis.ExecuteFunctionSendResult => {
    return (
      data !== null &&
      (data as Moralis.ExecuteFunctionSendResult).hash !== undefined &&
      typeof (data as Moralis.ExecuteFunctionSendResult).hash === 'string'
    );
  };

  const Copy = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="#1780FF"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{cursor: 'pointer'}}
      onClick={() => {
        if (hasHash(data)) {
          navigator.clipboard.writeText(data.hash!);
        } else {
          navigator.clipboard.writeText(' ');
        }
        setClicked(true);
      }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M15 3v4a1 1 0 0 0 1 1h4" />
      <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
      <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
      <title id="copy-address">Copy Transaction Hash</title>
    </svg>
  );

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle color="success">The transaction was successful!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please wait up to a few minutes for the transaction to be confirmed on
          the blockchain.
        </DialogContentText>
        <DialogContentText>
          <strong>Transaction hash: </strong>
          {hasHash(data) && getEllipsisTxt(data.hash!)}
          {clicked ? <Check /> : <Copy />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<RefreshIcon />}
          onClick={onClose}
          sx={{
            textTransform: 'none',
            mr: 1,
          }}
        >
          Refresh
        </Button>
        <Button
          href={`${getExplorer(chainId!)}tx/${hasHash(data) && data.hash!}`}
          target="_blank"
          rel="noreferrer"
          startIcon={<LaunchOutlinedIcon />}
          variant="contained"
          sx={{
            textTransform: 'none',
            topRightBorderRadius: 99,
            bottomRightBorderRadius: 99,
          }}
        >
          Etherscan
        </Button>
      </DialogActions>
    </Dialog>
  );
}
