import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer, {DrawerProps} from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

interface Page {
  title: string;
  path: string;
}

interface SidebarProps extends DrawerProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pages: Page[];
}

export default function Sidebar(props: SidebarProps) {
  const {open, setOpen, pages} = props;

  const toggleSidebar =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpen(open);
    };

  const SidebarList = () => (
    <Box
      sx={{width: 250}}
      role="presentation"
      onClick={toggleSidebar(false)}
      onKeyDown={toggleSidebar(false)}
    >
      <List>
        {pages.map(({title, path}, index) => (
          <Link href={path} color="inherit" underline="none" key={index}>
            <ListItem button key={title}>
              <>
                {
                  // TODO: we can add icons to the sidebar links if we want
                }
                <ListItemText primary={title} />
              </>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={toggleSidebar(false)}
      sx={{display: {xs: 'block', md: 'none'}}}
    >
      <SidebarList />
    </Drawer>
  );
}
