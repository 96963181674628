import AbstractWeb3Connector from 'moralis/lib/browser/Web3Connector/AbstractWeb3Connector';
import {CoinbaseWalletProvider} from '@coinbase/wallet-sdk';
import verifyChainId from '../../utils/verifyChainId';
import {getMoralisRpcs} from '../../utils/getMoralisRpcs';

const APP_NAME = 'Society of Derivative Apes';
const APP_LOGO_URL = '../../assets/sodalogo2.png';
const SPEEDY_NODES_KEY =
  process.env.REACT_APP_MORALIS_SPEEDY_NODES_KEY || '57669f043072f5193837b0dc';
const moralisRpcs = getMoralisRpcs(SPEEDY_NODES_KEY);

class CoinbaseWalletWeb3Connector extends AbstractWeb3Connector {
  type = 'CoinbaseWallet';
  account?: string;
  chainId: string | null = null;
  provider: CoinbaseWalletProvider | null = null;

  async activate() {
    try {
      await this.deactivate();
    } catch (error) {
      // Do nothing
    }

    if (!this.provider) {
      let CoinbaseWalletSDK;
      const config = {
        appName: APP_NAME,
        appLogoUrl: APP_LOGO_URL,
        darkMode: false,
      };

      try {
        CoinbaseWalletSDK = require('@coinbase/wallet-sdk')?.default;
      } catch (error) {
        // Do nothing, might not need Coinbase Wallet
      }

      if (!CoinbaseWalletSDK) {
        throw new Error(
          'Cannot enable Coinbase Wallet: dependency "@coinbase/wallet-sdk" is missing.'
        );
      }

      this.provider = new CoinbaseWalletSDK(config).makeWeb3Provider(
        moralisRpcs['0x1'],
        1
      );
    }

    if (!this.provider) {
      throw new Error(
        'Could not connect with Coinbase Wallet, error in connecting to provider.'
      );
    }

    const accounts = await this.provider.enable();
    const account = accounts[0].toLowerCase();
    const {chainId} = this.provider;
    const verifiedChainId = verifyChainId(chainId);

    this.account = account;
    this.chainId = verifiedChainId;

    this.subscribeToEvents(this.provider);

    return {
      provider: this.provider,
      chainId: verifiedChainId,
      account: this.account,
    };
  }

  async deactivate() {
    this.unsubscribeToEvents(this.provider);

    try {
      if (window) {
        window.localStorage.removeItem('coinbasewallet');
      }
    } catch (error) {
      // Do nothing
    }

    this.account = undefined;

    if (this.provider) {
      try {
        this.provider.disconnect();
      } catch {
        // Do nothing
      }
    }
  }
}

export default CoinbaseWalletWeb3Connector;
