import {useMemo, useState} from 'react';

export enum Flavor {
  Cherry,
  Orange,
  Lemon,
  Sour_Apple,
  Blue_Raspberry,
  Grape,
}

export const useFlavors = (totalUnclaimed: number) => {
  const [quantities, setQuantities] = useState<number[]>(Array(6).fill(0));

  const setFlavor = (flavor: Flavor, quantity: number) => {
    const newQuantities = [...quantities];
    newQuantities[flavor] = quantity;
    setQuantities(newQuantities);
  };

  const totalQuantity = useMemo(
    () => quantities.reduce((sum, value) => sum + value, 0),
    [quantities]
  );

  const incrementFlavor = (flavor: Flavor, amount: number) => {
    setFlavor(
      flavor,
      Math.min(
        quantities[flavor] + amount,
        totalUnclaimed - totalQuantity + quantities[flavor]
      )
    );
  };

  const decrementFlavor = (flavor: Flavor, amount: number) => {
    setFlavor(flavor, Math.max(quantities[flavor] - amount, 0));
  };

  const getQuantity = (flavor: Flavor) => {
    return quantities[flavor];
  };

  return {incrementFlavor, decrementFlavor, getQuantity, quantities};
};
