import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import AwesomePossumttf from './assets/fonts/AwesomePossum.ttf';

let theme = createTheme({
  palette: {
    primary: {
      main: '#fe98ff',
    },
    secondary: {
      main: '#99e2ff',
    },
  },
  typography: {
    fontFamily: ['AwesomePossum', 'sans-serif'].join(', '),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'AwesomePossum';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('AwesomePossum'), local('AwesomePossum-Regular'), url(${AwesomePossumttf}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
