import * as React from 'react';
import {useMoralis} from 'react-moralis';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  darken,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import {$enum} from 'ts-enum-util';
import {CoinbaseWalletProvider} from '@coinbase/wallet-sdk';
import {Flavor, useFlavors} from '../hooks/useFlavors';
import {useClaim} from '../hooks/useClaim';
import SuccessDialog from './SuccessDialog';

// const baseCansURI = 'ipfs://QmU39DDfTBhv1WXQwDEsp9mcEQTGtuPm6U1rZDpy9LiPE9/';

const CANS_COLORS = [
  '#ffa3a3',
  '#ffd3a3',
  '#fff99c',
  '#a3ffac',
  '#a3e8ff',
  '#a7a3ff',
];

// Import the images for cans dynamically.
// See: https://stackoverflow.com/q/42118296
const cansImages: Record<string, any> = {};
const req = require.context('../assets/cans', false, /\.(png|jpg)$/);

req.keys().forEach((key: string) => {
  cansImages[key.replace('./', '').replace('.jpg', '')] = req(key);
});

interface CanProps {
  flavor: Flavor;
  color: string;
}
interface CansProps {
  totalUnclaimed: number;
  claimIds: number[];
}

export default function Cans({totalUnclaimed, claimIds}: CansProps) {
  const {isAuthenticated, chainId, provider} = useMoralis();
  const {incrementFlavor, decrementFlavor, getQuantity, quantities} =
    useFlavors(totalUnclaimed);
  const {contractProcessor, claimCans} = useClaim();
  const [open, setOpen] = React.useState(false);

  const realChainId = React.useMemo(() => {
    if (chainId !== null) {
      return chainId;
    } else if (provider !== null) {
      return (provider as CoinbaseWalletProvider).chainId;
    } else {
      return null;
    }
  }, [chainId, provider]);

  const {data, isFetching} = contractProcessor;

  const totalClaiming = React.useMemo(
    () => quantities.reduce((sum, quantity) => sum + quantity),
    [quantities]
  );

  const claimingMaxed = React.useMemo(
    () => totalUnclaimed <= totalClaiming,
    [totalUnclaimed, totalClaiming]
  );

  React.useEffect(() => {
    if (data !== null) {
      setOpen(true);
    }
  }, [data]);

  const handleCloseDialog = () => {
    setOpen(false);
    window.location.reload();
  };

  const tooltip = React.useMemo(() => {
    if (!isAuthenticated) {
      return 'Connect your wallet to claim!';
    } else if (!totalClaiming) {
      return 'Select some cans to claim!';
    } else if (totalClaiming > totalUnclaimed) {
      return 'Reduce the number of selected cans!';
    } else if (realChainId !== '0x1') {
      return 'Switch to Ethereum Mainnet to claim!';
    }
    return '';
  }, [isAuthenticated, totalClaiming, totalUnclaimed, realChainId]);

  const Can = ({flavor, color}: CanProps) => {
    const buttonColor = darken(color, 0.3);

    return (
      <Card
        sx={{bgcolor: color, maxWidth: {xs: 300, md: 400}, margin: '0 auto'}}
      >
        <CardMedia
          component="img"
          src={cansImages[Flavor[flavor]].default}
          alt={Flavor[flavor]}
        />
        <CardContent>
          <Typography variant="h3" component="div">
            {Flavor[flavor].replace('_', ' ')}
          </Typography>
          <Typography variant="h5" component="span">
            Selected: {getQuantity(flavor)}
          </Typography>
        </CardContent>
        <CardActions disableSpacing sx={{justifyContent: 'center'}}>
          <ButtonGroup
            variant="contained"
            aria-label="decrement button group"
            size="small"
            color="inherit"
            sx={{borderRadius: 99}}
          >
            <Button
              onClick={() => decrementFlavor(flavor, 10)}
              disabled={getQuantity(flavor) === 0}
              sx={{
                borderTopLeftRadius: 99,
                borderBottomLeftRadius: 99,
                bgcolor: buttonColor,
                fontSize: 20,
              }}
            >
              -10
            </Button>
            <Button
              onClick={() => decrementFlavor(flavor, 1)}
              disabled={getQuantity(flavor) === 0}
              sx={{bgcolor: buttonColor, fontSize: 20}}
            >
              -1
            </Button>
            <Button
              onClick={() => incrementFlavor(flavor, 1)}
              disabled={claimingMaxed}
              sx={{bgcolor: buttonColor, fontSize: 20}}
            >
              +1
            </Button>
            <Button
              onClick={() => incrementFlavor(flavor, 10)}
              disabled={claimingMaxed}
              sx={{
                borderTopRightRadius: 99,
                borderBottomRightRadius: 99,
                bgcolor: buttonColor,
                fontSize: 20,
              }}
            >
              +10
            </Button>
          </ButtonGroup>
        </CardActions>
      </Card>
    );
  };

  const rainbowCans = (totalClaiming / 6) >> 0;

  const cansUntilRainbow = !totalClaiming ? 6 : 6 - (totalClaiming % 6);

  return (
    <Container maxWidth="xl" sx={{mt: 4}}>
      <Typography
        variant="h2"
        sx={{
          textAlign: 'center',
          textShadow: '-2px 2px 4px black',
          color: 'white',
          textTransform: 'uppercase',
          my: 2,
        }}
      >
        Claim Your Cans
      </Typography>
      <Box textAlign="center" sx={{flexGrow: 1}}>
        <Grid
          container
          spacing={{xs: 2, md: 3}}
          columns={{xs: 4, sm: 8, md: 12}}
          justifyContent="center"
        >
          {$enum(Flavor).map(flavor => (
            <Grid item xs={4} key={flavor}>
              <Can flavor={flavor} color={CANS_COLORS[flavor]} />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box textAlign="center">
        <Typography variant="h4" my={2}>
          Total selected: {totalClaiming}/{totalUnclaimed}
        </Typography>
        <Typography variant="h4">
          You will earn <strong>{rainbowCans} </strong> Rainbow Blast
          {rainbowCans === 1 ? '' : 's'}!
        </Typography>
        <Typography variant="h4">
          You need to claim <strong>{cansUntilRainbow}</strong> more can
          {cansUntilRainbow === 1 ? '' : 's'} to earn a
          {rainbowCans ? 'nother' : ''} Rainbow Blast!
        </Typography>
        <Tooltip title={tooltip}>
          <span>
            <LoadingButton
              variant="contained"
              onClick={() => claimCans(claimIds, quantities)}
              loading={isFetching}
              disabled={
                !isAuthenticated ||
                !totalClaiming ||
                totalUnclaimed < totalClaiming ||
                realChainId !== '0x1'
              }
              sx={{borderRadius: 99, my: 3, fontSize: 25}}
            >
              Claim Cans
            </LoadingButton>
          </span>
        </Tooltip>
      </Box>

      <SuccessDialog
        open={open}
        onClose={() => handleCloseDialog()}
        data={data}
      />
    </Container>
  );
}
