import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import bgImage from './assets/hirescloudy.jpg';

import {Footer, Navbar, NFTBalance, Welcome} from './components';

function App() {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Box minHeight="100vh">
        <Navbar />
        <Container maxWidth={false} sx={{textAlign: 'center'}}>
          <Welcome />
          <NFTBalance />
        </Container>
        <Footer />
      </Box>
    </Box>
  );
}

export default App;
